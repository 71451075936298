<template>
  <label
    v-if="isVisible"
    class="font-lato text-xxs flex w-fit cursor-pointer select-none items-center gap-x-3 uppercase"
    :for="uid + 'toggle'"
    :class="{ 'border-p-yellow-600 border-b pb-2': withBottomLine, 'toggle-selected': active }"
  >
    <span
      class="toggle relative inline-block cursor-pointer rounded-full transition-all duration-300"
      :class="labelClass"
      :style="labelStyle"
    >
      <input
        :id="uid + 'toggle'"
        class="opacity-0"
        type="checkbox"
        :checked="localActive"
        :disabled="disabled"
        @change="input"
      />
      <div
        class="toggle_thumb absolute h-4 w-4 rounded-full transition-all duration-300"
        :style="thumbStyle"
      />
    </span>
    <span class="toggle_slot" :style="{ color: labelColor }">
      <slot></slot>
    </span>
  </label>
</template>

<script lang="ts">
import { computed, ref, onMounted } from 'vue'
import tinycolor from 'tinycolor2'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~~/tailwind.config'
import { useCathodeStore } from '~/stores/cathode'

const fullConfig = resolveConfig(tailwindConfig)

const colors = fullConfig.theme.colors

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: colors.chart.yellow,
    },
    chartId: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    prices: {
      type: Boolean,
      default: false,
    },
    withBottomLine: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeToggle'],
  setup(props, { emit }) {
    const uid = getCurrentInstance()?.uid
    const localActive = ref(false)
    const cathodeStore = useCathodeStore()
    const themeInfo = ref({
      color: {
        on: '',
        off: '',
        thumb: '',
      },
    })

    const on = computed(() => {
      return props.prices ? props.active : localActive.value
    })

    const labelClass = computed(() => {
      const stateClass = on.value ? 'on' : 'off'
      return [stateClass]
    })

    const labelBackgroundColor = computed(() => {
      if (cathodeStore.scaleTextColor === '#000000') {
        return 'transparent'
      }

      if (on.value) return getColorWithOpacity(props.activeColor, 1)

      return themeInfo.value.color.off
    })

    const labelStyle = computed(() => {
      return {
        width: '34px',
        backgroundColor: labelBackgroundColor.value,
      }
    })

    const thumbStyle = computed(() => {
      const color =
        cathodeStore.scaleTextColor === '#000000' ? props.activeColor : 'rgba(0,0,0,0.5)'

      return {
        left: on.value ? '16px' : '3px',
        backgroundColor: on.value ? color : colors.white,
        transition: 'left 0.15s',
      }
    })

    const labelColor = computed(() => {
      if (!on.value) return colors['n-gray'][400]

      if (!props.chartId) return cathodeStore.scaleTextColor

      if (cathodeStore.selectedChartIdDownloadToImage === props.chartId) {
        return cathodeStore.scaleTextColor
      }

      return cathodeStore.baseScaleTextColor
    })

    const getColorWithOpacity = (color, opacity) => {
      const tinyColor = tinycolor(color)

      if (!tinyColor) return ''

      return tinyColor.setAlpha(opacity).toRgbString()
    }

    const input = () => {
      localActive.value = !localActive.value
      emit('changeToggle')
    }

    onMounted(() => {
      localActive.value = props.active
      themeInfo.value = {
        color: {
          on: getColorWithOpacity(props.activeColor, 0.5),
          off: colors['n-gray'][300],
          thumb: colors.chart.yellow,
        },
      }
    })

    return {
      on,
      labelClass,
      labelBackgroundColor,
      labelStyle,
      thumbStyle,
      labelColor,
      localActive,
      uid,
      getColorWithOpacity,
      input,
    }
  },
}
</script>

<style scoped>
.toggle {
  min-width: 34px;
  height: 18px;
}

.toggle_slot {
  word-break: break-word;
  width: calc(100% - 34px);
}

.toggle_thumb {
  top: 50%;
  transform: translateY(-50%);
}
</style>
